import PostCard from '../../components/PostCard'
import React from 'react'
import PropTypes from 'prop-types'
import css from '@styled-system/css'
import { graphql } from 'gatsby'
import get from 'lodash.get'
import { PageTransitionSpring } from '../../animation'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import {
  Heading,
  Text,
  Container,
  Box,
  Flex,
  Grid,
  Button,
  IconButton,
} from '../../components/UI'
import ScrollableLink from '../../components/ScrollableLink'
import { PageHeader } from '../../components/PageLayout'

const NewsPage = ({
  category = 'All',
  pageContext: { currentPage, numPages, slug },
  data: {
    contentfulPage: page,
    allContentfulPost: posts,
    allContentfulCategory: categories,
  },
}) => {
  const SeoImg =
    get(page, 'image.childImageSharp.sizes.src') || get(page, 'image.fluid.src')
  const path = category !== 'All' ? `/news/category/${slug}/` : '/news/'
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const hasOnePage = isFirst && isLast
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  return (
    <div>
      <SEO
        title={page.title}
        description={page.description}
        image={SeoImg}
        pathname={slug ? `${page.slug}/category/${slug}` : `${page.slug}`}
      />
      <Layout>
        <PageTransitionSpring animation="fromBottom">
          <PageHeader page={page} image={SeoImg} />
        </PageTransitionSpring>
        <PageTransitionSpring
          // animation="custom"
          // customAnimation={{
          //   from: {
          //     opacity: 0,
          //   },
          //   to: {
          //     opacity: 1,
          //   },
          // }}
          // delay={350}
          delay={200}
        >
          <Container mb={4}>
            <Flex
              w={1}
              align="center"
              flexDirection="row"
              my={[2, 2, 2, 3]}
              flexWrap="wrap"
            >
              <Box m={'xs'}>
                <Button
                  to={`/news/`}
                  // entryProps={{ length: 0, delay: 1 }}
                  // exitProps={{ length: 1, delay: 0 }}
                  as={ScrollableLink}
                  small
                  variant={category === 'All' ? 'primary' : 'outline'}
                >
                  All
                </Button>
              </Box>
              {categories.edges.map(({ node: { title, slug } }) => (
                <Box m={'xs'} key={slug}>
                  <Button
                    to={`/news/category/${slug}`}
                    // entryProps={{ length: 0, delay: 1 }}
                    // exitProps={{ length: 1, delay: 0 }}
                    small
                    as={ScrollableLink}
                    variant={
                      title.toLowerCase() === category.toLowerCase()
                        ? 'primary'
                        : 'outline'
                    }
                  >
                    {title}
                  </Button>
                </Box>
              ))}
            </Flex>
            <Grid
              as="ul"
              cols={{ _: 1, sm: 2, md: 3 }}
              gap={{ _: 2, md: 2, lg: 3 }}
            >
              {posts.edges.map(({ node: post }, i) => {
                return (
                  <li
                    key={i}
                    // css={css({
                    //   gridColumn: i === 0 && ['span 1', 'span 1', 'span 2'],
                    // })}
                  >
                    <PostCard post={post} />
                  </li>
                )
              })}
            </Grid>
            {!hasOnePage && (
              // Pagination
              <Flex align="center" flexDirection="row">
                {!isFirst && (
                  <ScrollableLink
                    to={`${path}${currentPage === 2 ? '' : currentPage - 1}`}
                  >
                    <Box m={'xs'}>
                      <IconButton
                        variant="ghost"
                        as="span"
                        small
                        name="angle-left"
                      ></IconButton>
                    </Box>
                  </ScrollableLink>
                )}
                {!isFirst && (
                  <ScrollableLink to={`${path}${prevPage}`}>
                    <Box m={'xs'}>
                      <Button variant={'ghost'} px={'xs'} py={1} as="span">
                        {currentPage - 1}
                      </Button>
                    </Box>
                  </ScrollableLink>
                )}
                <Box m={'xs'}>
                  <Button
                    disabled
                    px={'xs'}
                    py={1}
                    variant={'ghost'}
                    as="span"
                    small
                  >
                    {currentPage}
                  </Button>
                </Box>
                {!isLast && (
                  <ScrollableLink to={`${path}${currentPage + 1}`}>
                    <Box m={'xs'}>
                      <Button px={'xs'} py={1} variant={'ghost'} as="span">
                        {currentPage + 1}
                      </Button>
                    </Box>
                  </ScrollableLink>
                )}
                {!isLast && (
                  <ScrollableLink to={`${path}${nextPage}`}>
                    <Box m={'xs'}>
                      <IconButton
                        variant="ghost"
                        as="span"
                        small
                        name="angle-right"
                      ></IconButton>
                    </Box>
                  </ScrollableLink>
                )}
              </Flex>
            )}
          </Container>
        </PageTransitionSpring>
      </Layout>
    </div>
  )
}

NewsPage.propTypes = {}

export default NewsPage

export const NewsPageQuery = graphql`
  query NewsPageQuery($id: String!, $skip: Int, $limit: Int) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      heading
      subHeading
      image {
        fluid(
          maxWidth: 1600
          maxHeight: 350
          resizingBehavior: FILL
          cropFocus: BOTTOM
        ) {
          ...GatsbyContentfulFluid
        }
      }
      content {
        content
        json
      }
    }

    allContentfulCategory(
      sort: { fields: [title], order: ASC }
      filter: { post: { elemMatch: { id: { ne: null } } } }
    ) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }

    allContentfulPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          excerpt {
            excerpt
          }
          content {
            json
          }
          category {
            title
            slug
          }
          publishDate
          featuredImage {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
